<template>
    <div class="row px-3">
        <div class="col-12 mb-2 px-0">
            <div class="lblTiny fw-bold mb-3">User Status</div>

            <div class="row">
                <div class="col-lg-3">
                    <table class="table table-sm table-borderless mb-0">
                        <tbody>
                            <tr>
                                <td width="50px">
                                    <ImgAvatar key="1" isUser="false" displayName="" isVerified="false" isActive="" privilege="" width="30" height="30" />
                                </td>
                                <td class="align-middle">Non-user</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="col-lg-3">
                    <table class="table table-sm table-borderless mb-0">
                        <tbody>
                            <tr>
                                <td width="50px">
                                    <ImgAvatar key="4" isUser="true" displayName="" isVerified="false" isActive="PENDING" privilege="" width="30" height="30" />
                                </td>
                                <td class="align-middle">Pending user</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="col-lg-3">
                    <table class="table table-sm table-borderless mb-0">
                        <tbody>
                            <tr>
                                <td width="50px">
                                    <ImgAvatar key="2" isUser="true" displayName="" isVerified="false" isActive="" privilege="" width="30" height="30" />
                                </td>
                                <td class="align-middle">User</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="col-lg-3">
                    <table class="table table-sm table-borderless mb-0">
                        <tbody>
                            <tr>
                                <td width="50px">
                                    <ImgAvatar key="3" isUser="true" displayName="" isVerified="true" isActive="" privilege="" width="30" height="30" />
                                </td>
                                <td class="align-middle">KYC verified user</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="col-lg-3">
                    <table class="table table-sm table-borderless mb-0">
                        <tbody>
                            <tr>
                                <td width="50px">
                                    <ImgAvatar key="5" isUser="true" displayName="" isVerified="false" isActive="DISABLED" privilege="" width="30" height="30" />
                                </td>
                                <td class="align-middle">Expired / Disabled user</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="col-lg-3">
                    <table class="table table-sm table-borderless mb-0">
                        <tbody>
                            <tr>
                                <td width="50px">
                                    <ImgAvatar key="6" isUser="true" displayName="" isVerified="false" isActive="ACTIVE" privilege="OWNER" width="30" height="30" />
                                </td>
                                <td class="align-middle">Owner</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="col-lg-3">
                    <table class="table table-sm table-borderless mb-0">
                        <tbody>
                            <tr>
                                <td width="50px">
                                    <ImgAvatar key="7" isUser="true" displayName="" isVerified="false" isActive="ACTIVE" privilege="EDITOR" width="30" height="30" />
                                </td>
                                <td class="align-middle">Editor</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>

            <div class="col-12 pt-0">
                <hr />
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import Popper from 'vue3-popper'
// import Avatar from '@/components/Avatar.vue'
import ImgAvatar from '@/components/ImgAvatar.vue'

export default {
    name: 'LegendUser2',
    components: { Popper, /* Avatar, */ ImgAvatar },
    setup() {

        const showLegend = ref(false)

        return { showLegend }
    }
}
</script>

<style>
.legendContainer {
    width: 55%; 
    margin: 40px auto 0px auto;
    padding: 35px;
    border-radius: 10px;
}

.lblLegend a:hover {
    background-color: #EEEEEE;
    color: #6E777E;
    padding: 5px 8px;
    border-radius: 5px;
}
</style>